import React, { PureComponent } from 'react';
import queryString from 'query-string';
import {
  SinglePropertyHeaderSlider,
  SinglePropertyMainInfo,
  SinglePropertyContent,
  SinglePropertyAdvice,
  SinglePropertyPlots,
  SinglePropertySimilarSliderbyID,
  SinglePropertyMap,
} from 'components/sections';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import request from 'utils/request';
import {singlePageMotion, functionHeroAniamtion, functionSectionLoad1, functionSectionLoad2, functionSectionLoad3 } from 'motion/single-property.js';

import { PropertyDetailHighlights, StampDutyCalculator } from 'components/blocks';

import { ViewportEnterAnimation, Button, LightboxWrapper, CloseButton  } from 'components/ui';
import { functionSlider } from 'motion/new-homes.js';
import { functionSinglePropertyAdvice } from 'motion/buy.js';
import bp from 'config/responsive';
import $ from 'jquery';

const mockData = {

  similarPropertiesText: {
    title: 'Similar properties',
    description: 'We’ve found some more similar properties which you may find interesting.',
  }
}

const Loading = styled.div`
    opacity: 1;
    position: fixed;
    z-index: 3;
    background: #1f2d52;
    width: 100%;
    height: 100%;
`

const LoadingOverlay = styled.div`
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: #D9A547;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    animation: pulse 1s infinite ease;
  }

  @keyframes pulse {
    0%   { opacity: 0; transform: scale(0.2) }
    50% { opacity: 1; transform: scale(1.5) }
    100% { opacity: 0; transform: scale(0.2)}
  }
`

const FullSlide = styled.div`
	&.carousel-with-info {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		position: relative;

		.hideonMobile {
			display: none !important;
		}

		${bp.smallNotebook} {
			flex-direction: row;
		}

		& .slick-slider {
		   width: 100%;

		   ${bp.smallNotebook} {
			 width: 100%;
			}
		}
		& .slick-prev {
			top: 0;
			bottom: 0;
			margin: auto;
		}
		& .slick-next {
			right: 11px;
			left: inherit !important;
			top: 0;
			bottom: 0;
			margin: auto;
		}
	}
`
const FullSlideInner = styled.div`
	position: relative;
	width: 100%;

	${bp.smallNotebook} {
	  width: 60%;
	 }
	 ${bp.bigNotebook} {
 		width: 70%;
 	}
`

const ButtonsGroup = styled.div`

	position: absolute;
	z-index: 9;
	left: 0;
	bottom: 1.5rem;
	right: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	& button, & a {
		&:not(:last-child) {
			margin-right: .5rem !important;
		}
    	padding: 0px 10px !important;
		height: 46px !important;
		font-size: 14px !important;

		background: #0B1D3F !important;
		color: white !important;

		${bp.smallNotebook} {
			padding: 0px 20px !important;
	 	   	height: 56px !important;
			font-size: 16px !important;
	   	}

		&.active {
			background: #335eb3 !important;
			color: white !important;
		}
	}

	 ${bp.smallNotebook} {
		 left: 1.5rem;
	 	right: 1.5rem;
		bottom: 3rem;
	 }
`

const ButtonPhotos = styled(Button)`

	display: flex;

	 ${bp.smallNotebook} {

	 }
`
const ButtonFloorplan = styled(Button)`

	display: flex;

	 ${bp.smallNotebook} {

	 }
`

const ButtonLocation =styled(Button)`

	display: flex;

	 ${bp.smallNotebook} {

	 }
`

const FullSlideInfo = styled.div`
	width: 100%;

	& > div {
		padding-top: 0px;
	}

	${bp.smallNotebook} {
		width: 40%;
		min-width: 415px;

		& > div {
			padding-top: 70px;
		}
	}

	${bp.bigNotebook} {
		width: 30%;
	}
`

const ModalWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgba(255,255,255,0.95);
  top: 0;
  left: 0;
  z-index: 999;
`;

const Modal = styled.div`

    background: white;
    box-shadow: 0 0 30px rgba(0,0,0,0.2);
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
    padding: 40px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    color: #1a2f59;
    flex-direction: column;

    ${bp.mobile} {
      width:100%;
    }

    img {
      width: 100%;
      height:auto;
      max-height:90vh;
    }

    
    span {
      float: right;
      cursor:pointer;
    }
    p{
      margin-top: 15px;
      font-size: 18px;
    }
    & .pdfIcon {
      padding: 20px;
      background: #335eb3;
      border-radius: 100px;
      width: 110px;
      height: 110px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        opacity: 0.4;
      }
    }


`;

const CloseButtonWrapper = styled.div`
    position: absolute;
    right: 20px;
    top: 20px;

    & button {
      &:before {
        top: -8px !important;
      }
    }
`;


class SingleProperty extends PureComponent {
  state = {
    data: {
      similarProperties: [],
      map: { center: {} },
      content: {},
      mainInfo: '',
      highlights: { keyFeatures: [], ctaPhoneNumber: '' },
      sliderPhotos: [],
    },
    loading: true,
    error: false,
    isStampDutyOpen: false,
    modal: false,

  };

  getData = async () => {
    try {
      this.setState({ loading: true, error: false });
      const { id } = queryString.parse(this.props.location.search);
      const property = await request(`single-property/${id}`);
      const similarProperties = '';
      //const similarProperties = await request(`similar-properties/${id}`);
      this.setState({
        loading: false,
        data: {
          ...property.data,
          similarProperties: similarProperties.data,
        },
      });
    } catch (err) {
      this.setState({ loading: false, error: true });
    }
  };

  async componentDidMount() {
    this.getData();
  }

  toggleStampDutyModal = () => {
    const currentState = this.state.isStampDutyOpen;
    const nextState = !currentState;

    if (nextState) {
      window.closeModal && window.closeModal();
      window.closeModal = this.toggleStampDutyModal;
    } else {
      window.closeModal = undefined;
    }

    this.setState({ isStampDutyOpen: nextState });
  }

  ctaClick = () => {
    if (typeof window !== 'undefined') {
      window.openEnquiry && window.openEnquiry();
    }
  }

  ctaTogglemap = (e) => {

   	$('.togglegallery').hide();
   	$('.floorplantoggle').hide();
    $('.maptoggler').fadeIn();

    $('.button_slider').removeClass('active');
    $(e.target).addClass('active');

  }

  ctaTogglegallery = (e) => {

   	$('.togglegallery').fadeIn();
    $('.maptoggler').hide();
    $('.floorplantoggle').hide();
    $('.button_slider').removeClass('active');

    $(e.target).addClass('active');
    
  }

    ctaTogglefloorplan = (e) => {

   	$('.floorplantoggle').fadeIn();
    $('.maptoggler').hide();
    $('.togglegallery').hide();
    $('.button_slider').removeClass('active');

    $(e.target).addClass('active');
    
  }

  
  handleLink = (event) =>{
    event.preventDefault();
    this.setState({ modal: true });
  }
  handleModalClose = () =>{
    this.setState({ modal: false });
  }

  

  render() {
    const { data, loading, isStampDutyOpen, toggleStampDutyModal, modal } = this.state;
    const {
      highlights,
      getAdvice,
      similarProperties,
      similarPropertiesText,
      sliderPhotos,
      mainInfo,
      pricingAndAvailabilityText,
      pricingAndAvailabilityData,
      content,
      map,
    } = data;

    const modaltext = "File not available.";


    return (
      <Layout onVisible={singlePageMotion}>

        <SEO title={highlights.title} description={mainInfo} image={sliderPhotos[0]} />

        {loading
          ? (

          <Loading className="single-loading">
            <LoadingOverlay></LoadingOverlay>
          </Loading>

          )
          : (



     <React.Fragment>


      {modal &&
         <ModalWrapper>
            <Modal>
              <CloseButtonWrapper>
                <CloseButton onClick={() => this.handleModalClose()} />
              </CloseButtonWrapper>
                <img src={[highlights.downloads.floorplan]} alt="Brochure" />
            
            </Modal>
          </ModalWrapper>
        }


			<FullSlide className="carousel-with-info">
				<FullSlideInner>
					<ButtonsGroup>


						<ButtonPhotos className='button_slider active' onClick={this.ctaTogglegallery} ><i class="camera icon"></i>Photos</ButtonPhotos>


						
						 <React.Fragment>
						
								{highlights.floorplan != '' ? (
	                <ButtonFloorplan className='button_slider' onClick={(e) => this.handleLink(e)} href=""><i class="sticky note icon"></i>Floorplan</ButtonFloorplan>

	                ):(
	                     <div style={{ display: 'none' }}>
							        </div>
	                )}
	              
									<ButtonLocation className='button_slider' onClick={this.ctaTogglemap}><i class="map marker alternate icon"></i>Location</ButtonLocation>
 								</React.Fragment>



								</ButtonsGroup>

								<SinglePropertyHeaderSlider cover="cover" mobile="cover" photoList={sliderPhotos} />

								<div style={{ display: 'none' }} className='floorplantoggle'>
								<SinglePropertyHeaderSlider className='floorplan' cover="auto" mobile="contain"  photoList={[highlights.downloads.floorplan]} />
								</div>



 				{map && <SinglePropertyMap {...map} />}

				</FullSlideInner>

				<FullSlideInfo className="content-info">

					<PropertyDetailHighlights {...highlights} toggleStampDutyModal={toggleStampDutyModal} />

			    </FullSlideInfo>
			</FullSlide>


       
              <SinglePropertyContent {...content} />

              {pricingAndAvailabilityData && <SinglePropertyPlots
                {...pricingAndAvailabilityText}
                data={pricingAndAvailabilityData}
              />}


						{highlights.ribbon.text == 'NEW HOMES'  ? (


    					<ViewportEnterAnimation offset={100} animation={functionSlider}>
                <SinglePropertySimilarSliderbyID
                  content={mockData.similarPropertiesText} type="new"
                />
              </ViewportEnterAnimation>

               ) : (

               <ViewportEnterAnimation offset={100} animation={functionSlider}>
                <SinglePropertySimilarSliderbyID
                  content={mockData.similarPropertiesText} type="any"
                />
              </ViewportEnterAnimation>


            )}


              <ViewportEnterAnimation offset={100} animation={functionSinglePropertyAdvice}>
                {getAdvice && <SinglePropertyAdvice onClick={this.ctaClick} content={getAdvice} />}
              </ViewportEnterAnimation>
            </React.Fragment>
            )

        }
      </Layout>



    );
  }
}

export default SingleProperty;
